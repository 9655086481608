module.exports = {
  "account_page": null,
  "shopping_cart_page": [
    {
      "language": "en-US",
      "slug": "/shopping-cart"
    }
  ],
  "not_found_page": [
    {
      "language": "en-US",
      "slug": "/not-found"
    }
  ]
}